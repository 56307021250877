:root {
  --heart-ms: 0;
}

body {
  background-color: #cb4444;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Noto Sans JP, sans-serif;
  display: flex;
}

.main {
  filter: drop-shadow(4px 4px #a23636);
  background: #cb4444;
  border: solid #0003;
  border-radius: 1em;
  width: 240px;
  padding: 2em;
}

.logo {
  width: 240px;
}

.icon {
  width: 21px;
  height: 21px;
  margin: auto 6px;
}

.links {
  justify-content: center;
  display: flex;
}

.links ul {
  padding: 0;
  list-style-type: none;
  display: flex;
}

.tippy-box[data-theme~="oshiruko"] {
  filter: drop-shadow(4px 4px #a23636);
  color: #fde6ef;
  background: #cb4444;
  border: solid #0003;
  border-radius: 10px;
  padding: 8px;
}

.heart-link {
  filter: opacity(.2);
}

.heart-visible {
  animation: pulse var(--heart-ms) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

.heart-visible {
  filter: opacity();
  transition: all .3s;
}

.description {
  color: #fde6ef;
  opacity: .8;
  letter-spacing: 1px;
  border-top: 2px solid #0003;
  margin-bottom: 1em;
  padding-top: 1em;
  font-size: 13px;
  line-height: 1.75;
}

.message {
  border-top: 2px solid #0003;
  padding-top: 1em;
  display: flex;
}

.message input {
  color: #fde6ef;
  background-color: #0003;
  border: none;
  border-radius: 4px;
  flex-grow: 1;
  height: 30px;
  margin-right: 4px;
  padding-left: 1em;
  font-size: 13px;
}

.message input::placeholder {
  color: #fde6ef;
  opacity: .5;
}

.message button {
  opacity: .9;
  background-color: #fde6ef;
  border: none;
  border-radius: 4px;
  height: 32px;
  padding: 6px;
  transition: all .3s;
}

.message button:hover {
  opacity: 1;
  transition: all .3s;
}

.message button img {
  height: 100%;
}

.snackbar {
  visibility: hidden;
  filter: drop-shadow(4px 4px #a23636);
  color: #fde6ef;
  text-align: center;
  z-index: 1;
  background-color: #cb4444;
  border: solid #a23636;
  border-radius: 10px;
  min-width: 250px;
  margin-left: -125px;
  padding: 1em;
  position: fixed;
  bottom: 30px;
  left: calc(50% - 1em);
}

.snackbar.show {
  visibility: visible;
  animation: .5s fadein, .5s 4.5s fadeout;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 30px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}
/*# sourceMappingURL=index.e7e0c45e.css.map */
