:root {
  --heart-ms: 0;
}

body {
  background-color: #cb4444;
  margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans JP", sans-serif;
}

.main {
  width: 240px;
  padding: 2em;
  border-radius: 1em;
  border: solid rgba(0, 0, 0, 0.2);
  background: #cb4444;
  filter: drop-shadow(4px 4px 0 #a23636);
}

.logo {
  width: 240px;
}

.icon {
  margin: auto 6px;
  height: 21px;
  width: 21px;
}

.links {
  display: flex;
  justify-content: center;
}

.links ul {
  padding: 0;
  list-style-type: none;
  display: flex;
}

.tippy-box[data-theme~="oshiruko"] {
  border-radius: 10px;
  border: solid rgba(0, 0, 0, 0.2);
  background: #cb4444;
  filter: drop-shadow(4px 4px 0 #a23636);
  color: #fde6ef;
  padding: 8px;
}

.heart-link {
  filter: opacity(0.2);
}

.heart-visible {
  animation: pulse var(--heart-ms) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.heart-visible {
  filter: opacity(1);
  transition: 300ms;
}

.description {
  border-top: solid 2px rgba(0, 0, 0, 0.2);
  padding-top: 1em;
  margin-bottom: 1em;
  color: #fde6ef;
  opacity: 0.8;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 1.75;
}

.message {
  padding-top: 1em;
  border-top: solid 2px rgba(0, 0, 0, 0.2);
}

.message {
  display: flex;
}

.message input {
  height: 30px;
  flex-grow: 1;
  margin-right: 4px;
  padding-left: 1em;
  font-size: 13px;
  color: #fde6ef;
  border-radius: 4px;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.message input::placeholder {
  color: #fde6ef;
  opacity: 0.5;
}

.message button {
  height: 32px;
  padding: 6px 6px;
  border: none;
  border-radius: 4px;
  background-color: #fde6ef;
  opacity: 0.9;
  transition: 0.3s;
}

.message button:hover {
  opacity: 1;
  transition: 0.3s;
}

.message button img {
  height: 100%;
}

.snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #cb4444;
  border: solid #a23636;
  filter: drop-shadow(4px 4px 0 #a23636);
  color: #fde6ef;
  text-align: center;
  border-radius: 10px;
  padding: 1em;
  position: fixed;
  z-index: 1;
  left: calc(50% - 1em);
  bottom: 30px;
}

.snackbar.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
